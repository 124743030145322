/* eslint no-param-reassign: "off" */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  SerializedError,
} from '@reduxjs/toolkit';
import { format } from 'date-fns';
// import { addHours, isAfter, format } from 'date-fns';
import httpClient from 'utilities/httpClient';
import printVersion from 'utilities/printVersion';

const telemetryEnums = {
  HEARTBEAT: 'COAP_HEARTBEAT',
  HEARTBEAT_HISTORY: 'COAP_HEARTBEAT_HISTORY',
  MOTION: 'COAP_MOTION',
  SMART_FILE_UPLOAD: 'COAP_SMART_FILE_UPLOAD',
  REBOOT_EVENT: 'COAP_REBOOT_EVENT',
  COAP_MODEM_FW_VERSION: 'COAP_MODEM_FW_VERSION',
  SIM_ICCID_INFO: 'COAP_SIM_ICCID_INFO',
  MODEM_SIGNAL_QUALITY: 'COAP_MODEM_SIGNAL_QUALITY',

  DHF_FILE_PARSER: 'DHF_FILE_PARSER',
  DSF_FILE_PARSER: 'DSF_FILE_PARSER',
};
const logsAdapter = createEntityAdapter();

// raw aed logs by device
export const fetchRawAEDLogs = createAsyncThunk(
  'logs/fetchRawAEDLogs',
  async ({ deviceId }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        take: 500,
        filter: {
          filters: [
            {
              field: 'device___id',
              operator: 'eq',
              value: Number(deviceId),
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.SMART_FILE_UPLOAD,
            },
          ],
          logic: 'and',
        },
        sort: [
          {
            field: 'id',
            dir: 'desc',
          },
        ],
      }
    );
    return response.data;
  }
);

// monitor logs by device
export const fetchMonitorLogs = createAsyncThunk(
  'logs/fetchMonitorLogs',
  async ({ deviceId, params }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        ...params,
        sort: [
          {
            field: 'timeCreated',
            dir: 'desc',
          },
        ],
        filter: {
          filters: [
            //...params.filter.filters,
            {
              field: 'device___id',
              operator: 'eq',
              value: Number(deviceId),
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.HEARTBEAT_HISTORY,
            },
          ],
          logic: 'and',
        },
        // skip: 0,
        take: 100,
        // sort: [
        //   {
        //     field: 'id',
        //     dir: 'desc',
        //   },
        // ],
      }
    );
    return response.data;
  }
);

export const fetchGraphsLogs = createAsyncThunk(
  'logs/fetchGraphsLogs',
  async ({ deviceId, params, timeAgo }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        ...params,
        filter: {
          filters: [
            ...params.filter.filters,
            {
              field: 'timeCreated',
              operator: 'gte',
              value: timeAgo,
            },
            {
              field: 'device___id',
              operator: 'eq',
              value: Number(deviceId),
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.HEARTBEAT_HISTORY,
            },
          ],
          logic: 'and',
        },
        take: 100,
      }
    );
    return response.data;
  }
);

export const fetchSignalGraphsLogs = createAsyncThunk(
  'logs/fetchSignalGraphsLogs',
  async ({ deviceId, params, timeAgo }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        ...params,
        filter: {
          filters: [
            ...params.filter.filters,
            {
              field: 'timeCreated',
              operator: 'gte',
              value: timeAgo,
            },
            {
              field: 'device___id',
              operator: 'eq',
              value: Number(deviceId),
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.MODEM_SIGNAL_QUALITY,
            },
          ],
          logic: 'and',
        },
        take: 100,
      }
    );
    return response.data;
  }
);

export const fetchRebootReason = createAsyncThunk(
  'logs/fetchRebootReason',
  async ({ deviceId, params }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        ...params,
        filter: {
          filters: [
            ...params.filter.filters,
            {
              field: 'device___id',
              operator: 'eq',
              value: Number(deviceId),
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.REBOOT_EVENT,
            },
          ],
          logic: 'and',
        },
        take: 100,
      }
    );
    return response.data;
  }
);

// fetch all telemetry data for device main page
export const fetchAllMonitorLogs = createAsyncThunk(
  'logs/fetchAllMonitorLogs',
  async ({ timeAgo, params }: any) => {
    const response = await httpClient.post(
      '/service/rest/device-event/search',
      {
        ...params,
        filter: {
          filters: [
            ...params.filter.filters,
            {
              field: 'timeCreated',
              operator: 'gte',
              value: timeAgo,
            },
            {
              field: 'eventParser___name',
              operator: 'eq',
              value: telemetryEnums.HEARTBEAT_HISTORY,
            },
          ],
          logic: 'and',
        },
        take: 100,
      }
    );
    return response.data;
  }
);

const logsSlice = createSlice({
  name: 'logs',
  initialState: logsAdapter.getInitialState({
    // 'idle' | 'loading' | 'failed' | 'succeeded' |
    error: null as null | SerializedError,
    status: 'idle',
    monitorLogs: {
      results: [],
      total: -1,
      // 'idle' | 'loading' | 'failed' | 'succeeded' |
      statusMonitorLogs: 'idle',
    },
    rebootEventLogs: {
      results: [],
      total: -1,
      // 'idle' | 'loading' | 'failed' | 'succeeded' |
      statusRebootEventLogs: 'idle',
    },
    graphsLogs: {
      results: [] as any[],
      total: -1,
      // 'idle' | 'loading' | 'failed' | 'succeeded' |
      statusGraphsLogs: 'idle',
    },
    signalGraphsLogs: {
      results: [] as any[],
      // 'idle' | 'loading' | 'failed' | 'succeeded' |
      statusSignalGraphsLogs: 'idle',
    },
    aedLogs: {
      results: [],
      total: -1,
    },
    aedLogsRaw: {
      results: [],
      total: -1,
      // 'idle' | 'loading' | 'failed' | 'succeeded' |
      statusRawLogs: 'idle',
    },
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch heart beat logs
      .addCase(fetchMonitorLogs.pending, state => {
        state.monitorLogs.statusMonitorLogs = 'loading';
      })
      .addCase(fetchMonitorLogs.fulfilled, (state, action) => {
        state.monitorLogs.statusMonitorLogs = 'succeeded';

        const newMonitorLogs = action.payload.data.map((tel: any) => {
          if (tel?.data?.entries?.length > 1) {
            const findLatestTel = Math.max(
              ...tel?.data?.entries?.map((o: any) => o.timestamp),
              0
            );
            const newTel = tel?.data?.entries?.find(
              (latestTel: any) => latestTel.timestamp === findLatestTel
            );

            newTel.battery = tel?.data?.battery_at_min_temperature
              ? tel?.data?.battery_at_min_temperature
              : 0;
            newTel.humidity = newTel?.humidity ?? '-';
            newTel.pressure = newTel?.pressure ?? '-';
            newTel.temperature = newTel?.temperature ?? '-';

            return {
              ...tel,
              data: newTel,
              largeSetData: tel.data,
            };
          }
          const data = tel?.data?.entries ? tel?.data?.entries?.[0] : {};
          data.battery = tel?.data?.battery_at_min_temperature
            ? tel?.data?.battery_at_min_temperature
            : 0;

          return {
            ...tel,
            data,
            largeSetData: tel.data,
          };
        });

        state.monitorLogs.results = newMonitorLogs;
        state.monitorLogs.total = action.payload.total;
      })
      .addCase(fetchMonitorLogs.rejected, (state, action) => {
        state.monitorLogs.statusMonitorLogs = 'failed';
        state.error = action.error;
      })
      // fetch graphs heart beat logs
      .addCase(fetchGraphsLogs.pending, state => {
        state.graphsLogs.statusGraphsLogs = 'loading';
      })
      .addCase(fetchGraphsLogs.fulfilled, (state, action) => {
        state.graphsLogs.statusGraphsLogs = 'succeeded';

        const flatGraphsLogs = [] as any[];

        action.payload.data.forEach((tel: any) => {
          if (tel?.data?.entries?.length > 1) {
            //
            const battery = tel?.data?.battery_at_min_temperature
              ? tel?.data?.battery_at_min_temperature
              : 0;
            const timeCreatedEvent = tel?.timeCreated;
            tel?.data?.entries?.forEach((entry: any) => {
              flatGraphsLogs.push({
                ...entry,
                battery,
                timeCreatedEvent,
                niceDateFormat: format(entry?.timestamp, 'DD.MM.YYYY HH:mm:ss'),
              });
            });
          } else {
            const data = tel?.data?.entries ? tel?.data?.entries?.[0] : {};
            data.battery = tel?.data?.battery_at_min_temperature
              ? tel?.data?.battery_at_min_temperature
              : 0;
            data.timeCreatedEvent = tel?.timeCreated;
            data.niceDateFormat = format(
              data?.timestamp,
              'DD.MM.YYYY HH:mm:ss'
            );

            flatGraphsLogs.push(data);
          }
        });

        // final example, which I wanted to receive, or to have at the end
        // battery: 3.5539435661000005;
        // humidity: 36;
        // maxTemperatureTime: 1642287600000;
        // maximumTemperature: 3.2;
        // minTemperatureTime: 1642321800000;
        // minimumTemperature: 3;
        // niceDateFormat: '16.01.2022 13:05:45';
        // pressure: 971.8000000000001;
        // temperature: 3.1;
        // timeCreatedEvent: 1642334755000;
        // timestamp: 1642334745000;

        let currentDayTimestamp = flatGraphsLogs.length
          ? flatGraphsLogs[0].timestamp
          : 0;

        let tempArray = [] as any[];
        const finalDateArray = [] as any[];

        flatGraphsLogs.forEach((log, index) => {
          const currentTimestamp = new Date(currentDayTimestamp).setHours(
            0,
            0,
            0,
            0
          );
          const thatDay = new Date(log.timestamp).setHours(0, 0, 0, 0);

          if (currentTimestamp === thatDay) {
            tempArray.push({
              ...log,
              niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
            });
          } else {
            if (!tempArray.length)
              tempArray.push({
                ...log,
                niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
              });

            finalDateArray[
              format(currentDayTimestamp, 'DD.MM.YYYY HH:mm:ss') as any
            ] = tempArray;

            // reset variables
            currentDayTimestamp = log.timestamp;
            tempArray = [];

            // add the first telemetry ( usually DD.MM.YYYY 00:00:00) to the array
            tempArray.push({
              ...log,
              niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
            });
          }

          if (index === flatGraphsLogs.length - 1) {
            if (!tempArray.length)
              tempArray.push({
                ...log,
                niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
              });

            finalDateArray[
              format(currentDayTimestamp, 'DD.MM.YYYY HH:mm:ss') as any
            ] = tempArray;
          }
        });

        const finalGraphArray = [] as any[];

        Object.entries(finalDateArray).forEach(([key, value]) => {
          const findLatestTel = Math.max(
            ...value?.map((o: any) => o.timestamp),
            0
          );
          // this data is used for battery, humidity and pressure
          const newTel = value?.find(
            (latestTel: any) => latestTel.timestamp === findLatestTel
          );

          const minimumTemperature = Math.min(
            ...value.map((o: any) => o?.temperature)
          );
          // find date and time of minimum temperature
          const minTemperatureTime = value?.find(
            (latestTel: any) => latestTel.temperature === minimumTemperature
          );

          const maximumTemperature = Math.max(
            ...value?.map((o: any) => o?.temperature),
            0
          );
          // find date and time of maximum temperature
          const maxTemperatureTime = value?.find(
            (latestTel: any) => latestTel.temperature === maximumTemperature
          );

          newTel.minimumTemperature = minimumTemperature;
          newTel.maximumTemperature = maximumTemperature;

          newTel.minTemperatureTime = minTemperatureTime.timestamp;
          newTel.maxTemperatureTime = maxTemperatureTime.timestamp;

          finalGraphArray.push(newTel);
        });

        // LEFT ONLY FOR YOUR ARIEL, SO THAT YOU CAN CHECK IT OUT
        // console.log('333 Graph data are: ', flatGraphsLogs);
        // console.log('444 Graph data are: ', finalDateArray);
        // console.log('777 Graph data are: ', finalGraphArray);

        state.graphsLogs.results = finalGraphArray;

        state.graphsLogs.total = action.payload.total as number;
      })
      .addCase(fetchGraphsLogs.rejected, (state, action) => {
        state.graphsLogs.statusGraphsLogs = 'failed';
        state.error = action.error;
      })
      // fetch graphs heart beat logs
      .addCase(fetchSignalGraphsLogs.pending, state => {
        state.signalGraphsLogs.statusSignalGraphsLogs = 'loading';
      })
      .addCase(fetchSignalGraphsLogs.fulfilled, (state, action) => {
        state.signalGraphsLogs.statusSignalGraphsLogs = 'succeeded';

        const flatSignalQuality = [] as any[];

        action.payload.data.forEach((tel: any) => {
          const data = tel?.data ? tel?.data : {};
          data.timestamp = tel?.timeCreated;
          data.niceDateFormat = format(tel?.timeCreated, 'DD.MM.YYYY HH:mm:ss');

          flatSignalQuality.push(data);
        });

        // final example, which I wanted to receive, or to have at the end
        // maxRSRP: -69;
        // maxRSRPNiceDateFormat: '17.01.2022 08:16:21';
        // maxRSRPTime: 1642403781000;
        // minRSRP: -106;
        // minRSRPNiceDateFormat: '17.01.2022 08:11:47';
        // minRSRPTime: 1642403507000;

        let currentDayTimestamp = flatSignalQuality.length
          ? flatSignalQuality[0].timestamp
          : 0;

        let tempArray = [] as any[];
        const finalDateArray = [] as any[];

        flatSignalQuality.forEach((log, index) => {
          const currentTimestamp = new Date(currentDayTimestamp).setHours(
            0,
            0,
            0,
            0
          );
          const thatDay = new Date(log.timestamp).setHours(0, 0, 0, 0);

          if (currentTimestamp === thatDay) {
            tempArray.push({
              ...log,
              niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
            });
          } else {
            if (!tempArray.length)
              tempArray.push({
                ...log,
                niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
              });

            finalDateArray[
              format(currentDayTimestamp, 'DD.MM.YYYY HH:mm:ss') as any
            ] = tempArray;

            // reset variables
            currentDayTimestamp = log.timestamp;
            tempArray = [];

            // add the first telemetry ( usually DD.MM.YYYY 00:00:00) to the array
            tempArray.push({
              ...log,
              niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
            });
          }

          if (index === flatSignalQuality.length - 1) {
            if (!tempArray.length)
              tempArray.push({
                ...log,
                niceDateFormat: format(log.timestamp, 'DD.MM.YYYY HH:mm:ss'),
              });

            finalDateArray[
              format(currentDayTimestamp, 'DD.MM.YYYY HH:mm:ss') as any
            ] = tempArray;
          }
        });

        const finalSignalQualityArray = [] as any[];

        Object.entries(finalDateArray).forEach(([key, value]) => {
          let divider;
          if (value.length <= 15) {
            divider = 1;
            value.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          } else if (value.length > 15 && value.length <= 20) {
            divider = 2;
            const newArr = value.filter(
              (_: any, i: any) => i % 2 === 0 || i === 0
            );
            newArr.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          } else if (value.length > 20 && value.length <= 30) {
            divider = 3;
            const newArr = value.filter(
              (_: any, i: any) => i % 3 === 0 || i === 0
            );
            newArr.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          } else if (value.length > 30 && value.length <= 50) {
            divider = 4;
            const newArr = value.filter(
              (_: any, i: any) => i % 4 === 0 || i === 0
            );
            newArr.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          } else if (value.length > 50 && value.length <= 100) {
            divider = 5;
            const newArr = value.filter(
              (_: any, i: any) => i % 5 === 0 || i === 0
            );
            newArr.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          } else if (value.length > 100) {
            divider = 10;
            const newArr = value.filter(
              (_: any, i: any) => i % 10 === 0 || i === 0
            );
            newArr.forEach((signalData: any) => {
              finalSignalQualityArray.push(signalData);
            });
          }
        });

        state.signalGraphsLogs.results = finalSignalQualityArray;
      })
      .addCase(fetchSignalGraphsLogs.rejected, (state, action) => {
        state.signalGraphsLogs.statusSignalGraphsLogs = 'failed';
        state.error = action.error;
      })
      .addCase(fetchRebootReason.pending, state => {
        state.rebootEventLogs.statusRebootEventLogs = 'loading';
      })
      .addCase(fetchRebootReason.fulfilled, (state, action) => {
        state.rebootEventLogs.statusRebootEventLogs = 'succeeded';
        const newRebootEventLogs = action.payload.data.map((tel: any) => {
          return {
            timeCreated: tel.timeCreated,
            resetReason: tel.data.reset_reason,
            chip: tel.data.chip,
            firmwareVersion: printVersion(
              tel.data?.firmware_version_major,
              tel.data?.firmware_version_minor,
              tel.data?.firmware_version_patch,
              tel.data?.firmware_version_build
            ),
          };
        });

        state.rebootEventLogs.results = newRebootEventLogs;
        state.rebootEventLogs.total = action.payload.total;
      })
      .addCase(fetchRebootReason.rejected, (state, action) => {
        state.rebootEventLogs.statusRebootEventLogs = 'failed';
        state.error = action.error;
      })
      .addCase(fetchAllMonitorLogs.pending, state => {
        state.monitorLogs.statusMonitorLogs = 'loading';
      })
      .addCase(fetchAllMonitorLogs.fulfilled, (state, action) => {
        state.monitorLogs.statusMonitorLogs = 'succeeded';
        const newMonitorLogs = action.payload.data.map((tel: any) => {
          const data = tel?.data?.entries?.[0];
          data.battery = tel?.data?.battery_at_min_temperature;
          return { ...tel, data };
        });
        state.monitorLogs.results = newMonitorLogs;
        state.monitorLogs.total = action.payload.total;
      })
      .addCase(fetchAllMonitorLogs.rejected, (state, action) => {
        state.monitorLogs.statusMonitorLogs = 'failed';
        state.error = action.error;
      })
      // fetch raw self test logs
      .addCase(fetchRawAEDLogs.pending, state => {
        state.aedLogsRaw.statusRawLogs = 'loading';
      })
      .addCase(fetchRawAEDLogs.fulfilled, (state, action) => {
        state.aedLogsRaw.statusRawLogs = 'succeeded';
        state.aedLogsRaw.results = action.payload.data;
        state.aedLogsRaw.total = action.payload.total;
      })
      .addCase(fetchRawAEDLogs.rejected, (state, action) => {
        state.aedLogsRaw.statusRawLogs = 'failed';
        state.error = action.error;
      });
  },
});

export default logsSlice.reducer;
